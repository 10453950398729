<template>
  <div class="lab-index">
    <banner-bg :type="2">
      <div class="lab-index-base">
        <new-banner
          :bannerList="bannerList"
          :height="600"
        ></new-banner>
      </div>
    </banner-bg>
    <div class="lab-index-body">
      <Title
        title="选择好工具，编程更轻松"
        desc="云端创作矩阵，丰富的在线编程工具"
      ></Title>
      <ul class="lab-list">
        <li
          v-for="item in datas"
          :key="item.title"
        >
          <div class="img">
            <img
              :src="item.pic"
              alt=""
            >
          </div>
          <p class="title">{{item.title}}</p>
          <p class="desc">{{item.desc}}</p>
          <a
            class="button"
            :href="item.link"
            target="_blank"
          >开始创作</a>
        </li>
      </ul>
      <!-- <p class="other">更多实验室建设中，敬请期待(●'◡'●)</p> -->
    </div>
  </div>
</template>

<script>
import { getBanners } from '@/api/common';
import { LABS } from '@/utils/page-resource';
import BannerBg from '@/components/BannerBg.vue';
import NewBanner from '@/components/NewBanner.vue';
import Title from '@/components/Title';

export default {
  name: 'Lab-Index',
  components: {
    BannerBg,
    NewBanner,
    Title
  },
  data() {
    return {
      datas: LABS,
      bannerList: []
    };
  },
  created() {
    this.getBannerList();
  },
  methods: {
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'sys',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/lab.scss';
.banner-block {
  height: 300px;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
